import React from 'react'
import loader from '../images/loader.gif'
const Loader = () => {
  return (
    <div className="bg-light main-loader">
      <img className='' src={loader} alt=''/>
    </div>
  )
}

export default Loader